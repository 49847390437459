@media screen and (max-width: 1024px) {
    .pricePart {
        text-align: center;
    }

    .pricePart .filterBox {
        width: auto;
        margin: 12px 40px 0;
    }

    .pricePart .filterBox .filter {
        margin: 10px;
    }

    .pricePart .price {
        font-size: 48px;
        margin: 3% 0;
    }

    .pricePart .description {
        font-size: 24px;
    }

}