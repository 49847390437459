@media screen and (max-width: 637px) {

    .bgImage {
        background-size: 0px;
    }

    .filterPart, .resultFailPart, .pricePart {
        min-height: 0;
        width: 100vw;
        overflow-x: hidden;
    }


    .filterPart .description,
    .pricePart .description {
        font-size: 19px;
        text-align: center;
    }

    .filterPart .title {
        margin-top: 15px !important;
        font-size: 24px;
    }

    .filterPart .titlesecondary {
        font-size: 22px;
    }

    .filterPart .description {
        font-size: 18px;
    }

    .pricePart .title {
        font-size: 24px;
    }

    .pricePart .filterBox {
        border-radius: 15px;
        width: auto;
        margin: 10px 20px;
        padding: 0;
    }

    .pricePart .filterBox .filter {
        margin: 10px;
    }

    .filter .name {
        font-size: 18px;
    }

    .filter .value {
        font-size: 18px;
        margin-top: 5px;
    }

    .pricePart .price {
        font-size: 32px;
        margin: 4% 0;
    }

    .btnGroup {
        margin: auto;
        width: auto;
        justify-content: center !important;
    }

    .btnGroup .btn {
        padding: 4px 24px;
        margin: 10px;
    }

    .pricePart .input_mail {
        font-size: 16px;
        width: 80vw;
        margin: 10px 0;
    }

    .resultFailPart .description{
        font-size: 18px;
    }

    .resultFailPart .primarylogo {
        height: 100px;
        margin-top: 40px;
    }

    .resultFailPart .message {
        font-size: 18px;
        padding: 0 4%;
        margin: 10px 0 0;
    }

    .resultFailPart .message-highlight {
        margin: 0 8px;
    }

    .footerPart {
        width: 100%;
        padding: 10px;
    }

    .qrCodeview {
        height: auto;
        width: 100%;
    }

}