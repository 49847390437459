.bgColor {
    background: var(--backgroundColor);
}

.bgImage {
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 120px;
}

.bgImage-left {
    background-position: left bottom;
}

.bgImage-right {
    background-position: right bottom;

}

.resultFailPart, .pricePart, .filterPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 60px;
}

.filterPart .title {
    text-align: center;
    color: var(--titleColor);
    font-size: 27px;
    font-weight: 500;
    font-family: Montserrat, Arial;
    width: 100%;
}

.filterPart .titlesecondary {
    text-align: center;
    color: var(--underTextColor);
    font-size: 25px;
    font-weight: 700;
    font-family: proximanova_semibold, Arial;
    width: 100%;

}

.filterPart .description {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 22px;
    font-weight: 400;
    font-family: proximanova_regular, Arial;
    width: 100%;
    padding: 0 5%;
    margin-top: 2%;
}


.filterPart .autocomplete,
.filterPart .selectinput {
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    border-color: var(--borderColor);
    border-style: solid;
    border-width: 3px;
    border-radius: 4px;
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
}

.filterPart .selectinput-sm {
    width: 150px;
}

.filterPart .input-text,
.filterPart .selectState {
    color: rgba(16, 38, 106, 1);
    -webkit-text-stroke: 1px rgba(112, 112, 112, 1);
    font-weight: 400;
    font-family: proximanova_regular, Arial;
    width: 100%;
    border: none;
    outline: 0;
    z-index: 1;
    background: none;
}

.filterPart .selectState:disabled {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
}

.filterPart .input-text:focus,
.filterPart .selectState:focus {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.filterPart .selectinput .dropdown_icon {
    position: absolute;
    right: 0;
    z-index: 0;
    width: 20px;
    margin: 0 5px;
    transform: rotateZ(0deg);
}

.filterPart .selectinput .dropdown_icon_active {
    transform: rotateZ(180deg);
}

.filterPart .dropdown-list {
    position: absolute;
    width: 100%;
    top: 40px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid rgb(223, 223, 223);
    z-index: 10;
}

.filterPart .dropdown-list .dropdown-item {
    background-color: rgba(252, 252, 252, 1);
    font-family: proximanova_regular, Arial, serif;
}

.filterPart .dropdown-list .dropdown-item:hover {
    background-color: rgb(189, 189, 189);
}

.filterPart .submitbutton {
    margin-top: 30px;
    width: 100%;
    text-align: center;
}

.filterPart .submitbutton .btn {
    background-color: var(--buttonConfirmColor);
    border-style: none;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: Montserrat, Arial, serif;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 700;

}

.filterPart .submitbutton .btn:disabled {
    background-color: rgb(119, 119, 119) !important;
}

.pricePart .title {
    color: var(--titleColor);
    font-size: 40px;
    font-weight: 500;
    font-family: Montserrat, Arial, serif;
    text-align: center;
    margin-top: 5%;
}

.pricePart .filterBox {
    background-color: var(--backgroundColor);
    border-color: rgba(112, 112, 112, 1);
    border-style: none;
    border-width: 1px;
    border-radius: 30px;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    width: 1200px;
    margin-top: 25px;
    padding: 16px 24px;
}

.pricePart .filterBox .filter {
    text-align: center;
}

.filter .name {
    color: var(--titleColor);
    font-size: 24px;
    font-family: Montserrat, Arial, serif;
}

.filter .value {
    color: var(--underTextColor);
    font-size: 24px;
    font-family: Montserrat, Arial, serif;
    margin-top: 10px;
}

.pricePart .price {
    color: var(--titleColor);
    font-size: 56px;
    font-family: Montserrat, Arial;
    margin: 1% 0;
}

.pricePart .description {
    color: var(--titleColor);
    font-size: 32px;
    font-family: Montserrat, Arial;
}

.pricePart .message {
    margin-top: 1%;
    color: black;
    font-size: 20px;
    font-family: Montserrat, Arial;
}

.btnGroup {
    width: auto;
    margin-top: 16px;
}

.btnGroup .btn {
    color: rgb(0, 0, 0);
    font-size: 16 px;
    font-weight: 700;
    font-family: Montserrat, Arial;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    padding: 8px 32px;
    margin: 15px 20px 20px;
    border-radius: 40px;
}

.btnGroup .btn:disabled {
    background-color: rgb(119, 119, 119) !important;
}

.btn-green {
    background-color: var(--buttonConfirmColor);
}

.btn-gray {
    background-color: rgb(119, 119, 119);
}

.btn-blue {
    background-color: var(--buttonNewColor);
}

.resultFailPart .logostorebigger {
    height: 90px;
    min-width: 240px;
    margin-top: 20px;
    margin-bottom: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: var(--storeLogo);
}

.pricePart .input_mail {
    background-color: rgba(255, 255, 255, 1);
    border-color: var(--borderColor);
    border-style: solid;
    border-width: 4px;
    border-radius: 6px;
    font-family: Montserrat, Arial;
    font-size: 16px;
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    width: 350px;
    padding: 8px;
    margin: 20px 0 0px;
}

.resultFailPart {
    padding-top: 5%;
}

.resultFailPart .message {
    color: var(--titleColor);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 20px;
    font-weight: 700;
    font-family: Montserrat, Arial;
    padding: 0 10px;
    margin: 0 0;
    text-align: center;
}

.resultFailPart .message {
    font-size: 32px;
}

.resultFailPart .message-highlight {
    margin: 0 8px;
    color: var(--underTextColor);
}

.resultFailPart .description {
    text-align: center;
    color: var(--underTextColor);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-size: 24px;
    font-weight: 700;
    font-family: proximanova_semibold, Arial;
    width: 80%;
    margin: 20px;
}

.qr-group {
    text-align: center;
    margin: auto 24px auto 10px;
    font-size: 12px;
}

.qr-group button {
    border: none;
    outline: none;
    padding: 0;
    background: none;
    font-family: Montserrat, Arial;
}

.qr-group button:hover {
    text-decoration: underline;
}

.mr-1 {
    margin-right: 32px;
}

.popupBg {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.39);
    font-family: Montserrat, Arial;
}

.c-poiter {
    cursor: pointer;
}

.qrCodeview {
    height: 60vh;
}

.popupBg-visible {
    z-index: 4;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.39);
    font-family: Montserrat, Arial;
}

.popupContent {
    transform: translateY(-50vh);
    margin: auto;
    padding: 10px;
    max-height: 80vh;
    max-width: 90vw;
    overflow: auto;
    border-radius: 15px;
    opacity: 0;
    background-color: rgb(255, 255, 255);
    transition: all 0.5s ease;
}

.popupContent-visible {
    opacity: 1;
    transform: translateY(0);
}

.popupClose {
    position: absolute;
    right: 30px;
    top: 30px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: rgb(255, 255, 255);
}

.popupClose:hover {
    background-color: rgb(219, 217, 217);
}


/* * {

    border: 1px solid red;
} */