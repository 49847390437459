.notification-container {
    position: fixed;
    top: 10px;
    right: 12px;
    z-index: 5;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
    font-family: proximanova_semibold, Arial;
}

.notification {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 15px;
    padding: 12px;
    min-width: 320px;
    min-height: 16px;
    border-radius: 8px;
    box-shadow: 4px 7px 10px 0 rgb(180, 180, 180);
    color: rgb(255, 255, 255);
    background-color: white;
    opacity: 0.9;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.notification:hover {
    opacity: 1;
}

.notification-content {
    flex: 1;
}

.notification-title {
    font-weight: 700;
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 0;
}

.notification-message {
    font-size: 1em;
    margin-bottom: 0;
}

.notification-image {
    height: 32px;
    margin-right: 12px;
}

.close {
    position: relative;
    width: 32px;
    height: 32px;
    opacity: 0.7;
}

.close:hover {
    opacity: 1;
}

.close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 3px;
    background-color: rgb(255, 255, 255);
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}